import { Drawer, Dropdown, Menu } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { badgePayPeriod } from "../../../../services/api.services";
import { getTimeEntries, setGlobalToastr } from "../../../../store/actions";
import ButtonDropdown from "../../../../common/components/EditableText/Dropdown";

const DashboardSidebar = React.forwardRef(({ isShow, currentUserInfo, bagdeTime, row }, ref) => {
  const dispatch = useDispatch();

  const [isBadgedIn, setIsBadgedIn] = useState(false);
  const [isTravelBadgedIn, setIsTravelBadgedIn] = useState(false);
  const [userIP, setUserIP] = useState(null);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const locations = useSelector((state) => state.common.locations);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (locations?.length > 0) {
      const temp = locations.map((location) => {
        return {
          display: location.value,
          value: location.id,
        };
      });
      temp.unshift({ display: "Home Address", value: -1 });
      setOptions(temp);
    }
  }, [locations]);

  useEffect(() => {
    if (bagdeTime?.length > 0) {
      if (bagdeTime[0]["status"] === 0) {
        if (bagdeTime[0]["type"] === "travel") {
          setIsTravelBadgedIn(false);
        } else {
          setIsBadgedIn(false);
        }
      } else {
        if (bagdeTime[0]["type"] === "travel") {
          setIsTravelBadgedIn(true);
        } else {
          setIsBadgedIn(true);
        }
      }
      if (bagdeTime[0]["user_ip_out"]) {
        setUserIP(bagdeTime[0]["user_ip_out"]);
      } else if (bagdeTime[0]["user_ip_in"]) {
        setUserIP(bagdeTime[0]["user_ip_in"]);
      } else {
        setUserIP(null);
      }
    }
    if (userIP && !isBadgedIn) {
      dispatch(
        setGlobalToastr({
          type: "info",
          message: `Your geolocation has been recorded`,
        })
      );
    }
  }, [bagdeTime]);

  const recordPayPeriod = async () => {
    const response = await badgePayPeriod();
    if (response) {
      dispatch(getTimeEntries(currentUserInfo.id, ""));
    }
  };

  const recordTravelPayPeriod = async (locationId, row) => {
    const response = await badgePayPeriod("travel", locationId);
    if (response) {
      dispatch(getTimeEntries(currentUserInfo.id, ""));
    }
  };

  if (!currentUserInfo) {
    return null;
  }

  return (
    <Drawer
      title={currentUserInfo?.name + (currentUserInfo.position && `( ${currentUserInfo.position} )`) || " "}
      placement="right"
      closable={false}
      className={sideNavStatus ? "wide" : ""}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              paddingBottom: "2rem",
              marginTop: "1.5rem",
            }}
          >
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Name"} value={currentUserInfo?.name} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Address"} value={currentUserInfo?.address} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Apt"} value={currentUserInfo?.address_2} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"City"} value={currentUserInfo?.city} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"State"} value={currentUserInfo?.state} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"SSN"} value={currentUserInfo?.ssn} mask={"ssn"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Phone"} value={currentUserInfo?.phone} mask={"phone"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"E-Mail"} value={currentUserInfo.email} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Employee Score"} value={currentUserInfo.points} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Wage"} value={currentUserInfo.meta && currentUserInfo.meta.salary} prefix={"$"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Start Date"}
                value={currentUserInfo.meta && moment(currentUserInfo.meta.start_date).format("MM/DD/YYYY")}
                type={"date"}
                noEditable={true}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"PTO Hours"} value={currentUserInfo.vacation_hours_per_year} unit={"hours"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Hours remaining this Pay Period"} value={row?.remaining_hours} unit={"hours"} />
            </div>
            <div className="actionContent" style={isTravelBadgedIn ? { paddingRight: "160px" } : {}}>
              {isTravelBadgedIn || (!isBadgedIn && !isTravelBadgedIn) ? (
                <ButtonDropdown
                  options={options}
                  onSelect={(item, index) => {
                    recordTravelPayPeriod(item, options[index]);
                  }}
                  customWidth={"300px"}
                  label={!isTravelBadgedIn ? "Travel Badge In" : "Travel Badge Out"}
                  isOutlined={false}
                />
              ) : null}
              {isBadgedIn || (!isBadgedIn && !isTravelBadgedIn) ? (
                <button onClick={recordPayPeriod}>{!isBadgedIn ? "On-Site Badge In" : "On-Site Badge Out"}</button>
              ) : null}
            </div>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default DashboardSidebar;
