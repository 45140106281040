/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { getLocal, removeLocal } from "../../../helpers/Local";
import { loginUserActivity, resetAllStatus, uploadUserMedia, logoutUserActivity } from "../../../store/actions";
import { RightArrowSVG } from "../icons/SVGIcon";
import "./style.scss";
import { env } from "process";

const useOutsideDetect = (ref1, ref2, handleClick, active) => {
  const handleClickOutside = (event) => {
    if (active && ref1.current && !ref1.current.contains(event.target) && ref2.current && !ref2.current.contains(event.target)) {
      handleClick(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

const HeaderIconMenu = () => {
  const userRole = getLocal("cvai-auth-role");
  const [openUser, setOpenUser] = useState(false);
  const history = useHistory();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  let logoutTimer;

  useOutsideDetect(ref1, ref2, setOpenUser, openUser);

  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    setupInactivityEvents();
    resetTimer(); // Start the inactivity timer

    return () => {
      clearTimeout(logoutTimer);
      removeInactivityEvents(); // Cleanup event listeners
    };
  }, []);

  useEffect(() => {
    const handleUserActivity = () => {
      setLastActivityTime(Date.now());
    };

    const handleUserInactive = () => {
      const params = {
        logout_time: new Date().toISOString(),
      };
      dispatch(logoutUserActivity(params));
    };

    const handleUserActive = () => {
      const params = {
        login_time: new Date().toISOString(),
      };
      dispatch(loginUserActivity(params));
    };

    const activityEvents = ["mousemove", "click", "keypress"];
    activityEvents.forEach((event) => window.addEventListener(event, handleUserActivity));

    const activityInterval = setInterval(() => {
      if (Date.now() - lastActivityTime < 30 * 60 * 1000) {
        handleUserActive();
      }
    }, 10 * 60 * 1000);

    const inactivityTimeout = setTimeout(() => {
      if (Date.now() - lastActivityTime >= 30 * 60 * 1000) {
        handleUserInactive();
      }
    }, 30 * 60 * 1000);

    return () => {
      activityEvents.forEach((event) => window.removeEventListener(event, handleUserActivity));
      clearInterval(activityInterval);
      clearTimeout(inactivityTimeout);
    };
  }, [lastActivityTime]);

  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const handleLogOut = () => {
    removeLocal("cvai-auth-token");
    removeLocal("cvai-token-type");
    removeLocal("cvai-token-expires");
    removeLocal("cvai-auth-role");
    removeLocal("cvai-current-user-provider-name");
    removeLocal("cvai-current-user-name");
    removeLocal("cvai-current-user");
    removeLocal("cvai-current-user_status");
    removeLocal("patien_id_search");
    removeLocal("current_patient_id");
    setOpenUser(false);
    dispatch(resetAllStatus());

    setTimeout(() => {
      history.push("/login");
    }, 1000);
  };

  const resetTimer = () => {
    if (process.env.NODE_ENV === "development") return; // Skip in development mode
    if (logoutTimer) clearTimeout(logoutTimer);
    // Set a new timer for 1 hour (14400000 ms)
    logoutTimer = setTimeout(handleLogOut, 14400000); // 4 hour
  };

  const setupInactivityEvents = () => {
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("scroll", resetTimer);
    window.addEventListener("click", resetTimer);
  };

  const removeInactivityEvents = () => {
    window.removeEventListener("mousemove", resetTimer);
    window.removeEventListener("keydown", resetTimer);
    window.removeEventListener("scroll", resetTimer);
    window.removeEventListener("click", resetTimer);
  };

  const getDisplayMedia = (options) => {
    if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
      return navigator.mediaDevices.getDisplayMedia(options);
    }
    if (navigator.getDisplayMedia) {
      return navigator.getDisplayMedia(options);
    }
    if (navigator.webkitGetDisplayMedia) {
      return navigator.webkitGetDisplayMedia(options);
    }
    if (navigator.mozGetDisplayMedia) {
      return navigator.mozGetDisplayMedia(options);
    }
    throw new Error("getDisplayMedia is not defined");
  };

  const takeScreenshotStream = async () => {
    const width = window.outerWidth * (window.devicePixelRatio || 1);
    const height = window.outerHeight * (window.devicePixelRatio || 1);

    const errors = [];
    let stream;
    const mediaStreamConstraints = {
      audio: false,
      video: {
        width,
        height,
        frameRate: 1,
      },
    };

    try {
      stream = await getDisplayMedia(mediaStreamConstraints);
    } catch (ex) {
      errors.push(ex);
    }

    if (errors.length) {
      if (!stream) {
        throw errors[errors.length - 1];
      }
    }

    return stream;
  };

  const handleCaptureScreen = async () => {
    try {
      const stream = await takeScreenshotStream();
      const video = document.createElement("video");

      const png = await new Promise((resolve, reject) => {
        video.onloadedmetadata = () => {
          video.play();
          video.pause();
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const context = canvas.getContext("2d");
          context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          resolve(canvas);
        };
        video.srcObject = stream;
      });

      stream.getTracks().forEach(function (track) {
        track.stop();
      });

      if (png == null) {
        throw new Error("Cannot take canvas screenshot");
      }

      const payload = {
        user_id: currentUserInfo?.id,
        type: "image",
        data: png.toDataURL("png/image"),
      };

      await dispatch(uploadUserMedia(payload));
      history.push("/support/details?is_captured=true");
    } catch (error) {
      console.error(error?.message);
    }
  };

  return (
    <ul className="headerIconMenuContainer">
      <li className="iconMenuList accountMenu hide-mobile">
        <NavLink to="#" onClick={() => setOpenUser(false)} style={{ padding: "0", display: "flex", alignItems: "center" }}>
          {currentUserInfo && Object.keys(currentUserInfo).length ? `${currentUserInfo?.name || currentUserInfo?.full_name}'s Account` : ""}
        </NavLink>
      </li>
      {userRole === "user" && (
        <li className="iconMenuList">
          <NavLink to="#" style={{ paddingRight: "0px" }}>
            <Icon type="camera" onClick={handleCaptureScreen} />
          </NavLink>
        </li>
      )}
      <li className="iconMenuList" ref={ref1}>
        <a className={openUser ? "opened-user" : ""} onClick={() => setOpenUser(!openUser)}>
          <Icon type="user" />
        </a>
        <ul ref={ref2} className={`userMenuList ${openUser ? "open" : ""}`}>
          <li className="menuList show-mobile">
            <a href={"#"} style={{ color: "#fff", textDecoration: "none" }}>
              {currentUserInfo && currentUserInfo?.name ? `${currentUserInfo?.name}` : ""}
            </a>
          </li>
          <hr className="show-mobile" />
          <li className="menuList" style={{ marginTop: "1.5rem" }}>
            <a href={"#"}>Profile and settings</a>
          </li>
          <li className="menuList">
            <a href={"#"}>Privacy</a>
          </li>
          <li className="menuList">
            <a href={"#"}>Feedback</a>
          </li>
          <li onClick={handleLogOut} className="menuList logout">
            <a>
              Log out <RightArrowSVG />
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default HeaderIconMenu;
